import React from 'react'

const htmlSerializer = (type, node, text, children, key) => {
  switch (type) {
    case 'image':
      return (
        <div key={key} className='content-image'>
          <img src={`${node.url}&w=1004`} alt={node.alt || ''} />
        </div>
      )
    default:
      return null
  }
}

export default htmlSerializer
