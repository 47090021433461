exports.linkResolver = (doc) => {
  if (doc.uid !== 'index' && doc.type === 'page') return `/${doc.uid}/`
  if (doc.type === 'category') {
    return `/category/${doc.uid}/`
  }
  if (doc.type === 'issue') {
    return `/issues/${doc.uid}/`
  }
  if (doc.type === 'story') {
    return `/stories/${doc.uid}/`
  }
  return `/`
}
