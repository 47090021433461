import React from 'react'

import { linkResolver } from './linkResolver'

export const repositoryConfigs = [
  {
    repositoryName: process.env.GATSBY_PRISMIC_MAIN_REPOSITORY_NAME,
    linkResolver,
    componentResolver: {
      category: React.lazy(() => import('../templates/category')),
      issue: React.lazy(() => import('../templates/issue')),
      page: React.lazy(() => import('../templates/page')),
      story: React.lazy(() => import('../templates/story'))
    }
  }
]
