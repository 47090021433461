exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-404-jsx": () => import("./../../../src/templates/404.jsx" /* webpackChunkName: "component---src-templates-404-jsx" */),
  "component---src-templates-category-jsx": () => import("./../../../src/templates/category.jsx" /* webpackChunkName: "component---src-templates-category-jsx" */),
  "component---src-templates-issue-jsx": () => import("./../../../src/templates/issue.jsx" /* webpackChunkName: "component---src-templates-issue-jsx" */),
  "component---src-templates-issues-jsx": () => import("./../../../src/templates/issues.jsx" /* webpackChunkName: "component---src-templates-issues-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-stories-jsx": () => import("./../../../src/templates/stories.jsx" /* webpackChunkName: "component---src-templates-stories-jsx" */),
  "component---src-templates-story-jsx": () => import("./../../../src/templates/story.jsx" /* webpackChunkName: "component---src-templates-story-jsx" */),
  "slice---src-components-categories-js": () => import("./../../../src/components/categories.js" /* webpackChunkName: "slice---src-components-categories-js" */),
  "slice---src-components-signup-block-js": () => import("./../../../src/components/signupBlock.js" /* webpackChunkName: "slice---src-components-signup-block-js" */),
  "slice---src-components-signup-js": () => import("./../../../src/components/signup.js" /* webpackChunkName: "slice---src-components-signup-js" */),
  "slice---src-components-sponsored-banner-js": () => import("./../../../src/components/sponsoredBanner.js" /* webpackChunkName: "slice---src-components-sponsored-banner-js" */),
  "slice---src-components-trending-stories-js": () => import("./../../../src/components/trendingStories.js" /* webpackChunkName: "slice---src-components-trending-stories-js" */)
}

